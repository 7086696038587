import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { Provider } from 'react-redux';
import LoadingBar from 'react-top-loading-bar';
import Layout from '../components/layout';
import Meta from '../components/Meta';
import UserContext from '../components/UserContext';
import { store } from '../redux/store';
import '../styles/globals.css';
import '../styles/benifits.css';
import '../styles/lightbox.css';
import '../styles/custom.css';
import '../styles/gaming.css';
import Script from 'next/script';
import Gototop from '../components/gototop';
import CookieConsent from '../components/CookieConsent';
import Head from 'next/head';
import * as gtag from '../helpers/gtag';
import { DataRefreshContext } from '../components/refreshContext';
import { Web3WalletProvider } from '../components/Web3WalletProvider';
import { Toaster } from 'react-hot-toast';
import { useLastMintingsNotification } from '../components/minting/lastMintingsNotification';
import { AcceptTermsWrapper } from '../components/acceptTermsWrapper';
import { CartModal } from '../components/cart/cart-modal';
import { CartContext } from '../components/cart/useCartFunctions';
import { FloorSweepContext } from '../components/floor-sweep/useFloorSweep';
import dynamic from 'next/dynamic';
import Gotoswapwidget from '../components/Widgets/goToSwapWidget';
import WidgetBotDiscord from '../components/Widgets/WidgetBotDiscord';

const ThemeProvider = dynamic(() => import('next-themes').then((mod) => mod.ThemeProvider), {
  ssr: false,
});

function MyApp({ Component, pageProps }) {
  const [cartContextData, setCartContextData] = useState({
    buyEnabled: true,
    itemList: [],
    isCartOpen: false,
  });
  const [floorSweepContextData, setFloorSweepContextData] = useState({
    sweepCount: 0,
    sweepingItems: [],
    sweepValue: 0,
    sweepStatus: 'sweep',
  });
  const router = useRouter();
  const ref = useRef(null);
  const swapRef = useRef(null);
  const pid = router.asPath;
  const [dataRefresh, setDataRefresh] = useState(new Date().getTime());
  const [isVisible, setIsVisible] = useState(false);
  const [disableSwapButton, setDisableSwapButton] = useState(false);

  const refresh = () => {
    setDataRefresh(new Date().getTime());
  };

  const scrollRef = useRef({
    scrollPos: 0,
  });

  useEffect(() => {
    // if (typeof window === 'undefined') {
    //   ref.current?.continuousStart();
    // } else {
    ref.current?.complete();
    // }
  }, []);

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
      setIsVisible(false);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useLastMintingsNotification();

  useEffect(() => {
    const handleScroll = () => {
      if (disableSwapButton) return;

      const element = document.getElementById('SquadSwapWidget');
      if (!element) {
        setIsVisible(false);
      } else {
        const top = element.getBoundingClientRect().top;
        const bottom = element.getBoundingClientRect().bottom;
        setIsVisible(top >= 0 && bottom <= window.innerHeight);
      }
    };

    // Scroll olayını dinle
    window.addEventListener('scroll', handleScroll);

    // Component kaldırıldığında event listener'ı temizle
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [disableSwapButton]);

  return (
    <>
      <Toaster position='top-right' containerClassName='!top-[var(--header-height)]' />
      <DataRefreshContext.Provider value={{ refreshDate: dataRefresh, refreshHooks: refresh }}>
        <Head key={'gtag'}>
          <script
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${gtag.GA_TRACKING_ID}', {
                  page_path: window.location.pathname,
                });
              `,
            }}
          />
        </Head>
        <Meta title='Home || Bit5 | Create. Collect. Connect.' />
        <Script
          strategy='afterInteractive'
          src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
        />
        <LoadingBar className='z-50' ref={ref} color='#8358ff' />
        <div className='fixed right-6 bottom-[80px] z-50 flex flex-col items-end gap-2'>
          <Gototop
            onClick={() => {
              setIsVisible(false);
              setDisableSwapButton(true);
              setTimeout(() => {
                setDisableSwapButton(false);
              }, 800);
            }}
          />
          <Gotoswapwidget swapRef={swapRef} isVisible={isVisible} />
          <WidgetBotDiscord server={'1057184902874939494'} channel={'1057290290261655552'} />
        </div>

        <Provider store={store}>
          <ThemeProvider enableSystem={false} attribute='class' defaultTheme='dark'>
            <Web3WalletProvider>
              <AcceptTermsWrapper>
                <UserContext.Provider value={{ scrollRef: scrollRef }}>
                  <CartContext.Provider value={{ ...cartContextData, setCartContextData }}>
                    <FloorSweepContext.Provider
                      value={{ ...floorSweepContextData, setFloorSweepContextData }}
                    >
                      {pid === '/login' ? (
                        <Component {...pageProps} />
                      ) : (
                        <>
                          <Layout>
                            <div className='h-[var(--header-height)]' />
                            {/*{pid}*/}
                            <Component {...pageProps} key={router.asPath.split('?')[0]} />
                          </Layout>
                          <CartModal />
                        </>
                      )}
                    </FloorSweepContext.Provider>
                  </CartContext.Provider>
                  <CookieConsent />
                </UserContext.Provider>
              </AcceptTermsWrapper>
            </Web3WalletProvider>
          </ThemeProvider>
        </Provider>
      </DataRefreshContext.Provider>
    </>
  );
}

export default MyApp;

const UWWrappedBadge = ({ item }) => {
  if (!item) return null;
  if (!item.collection) return null;
  if (!item.collection.mirrorAddress) return null;

  if (item.collection.contractType !== 'DN404') return null;

  return (
    <div
      style={{
        //filter: `grayscale(${rarityRatio}%)`,
        background: '#9172FF',
      }}
      className={`via-red-500 absolute left-1 bottom-1 z-[1] flex min-w-[50px] cursor-pointer items-center justify-between rounded-lg border-jacarta-100 px-2 py-1  text-white transition-all group-hover/price:bg-accent dark:text-[#FFF] sm:left-3 sm:bottom-3`}
    >
      <div className='ml-[2px] font-display text-sm'>
        <div className='text-xs sm:text-sm'>Wrapped By</div>
        <p className='text-[0.625rem] sm:text-xs'>Universal Wrapper</p>
      </div>
    </div>
  );
};

export default UWWrappedBadge;

import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { getCollectionPath, getItemPath } from '../../helpers/path';
import { getCdnUrl } from '../../lib/cdnUrl';
import { constants } from '../../helpers/constants';
import { useGetCollectionGet } from '../../hooks/useGetCollection';
import { ChainIcon } from '../Icon';
import getChainName, { getTokenName } from '../../helpers/getChainName';
import { useAccount } from 'wagmi';
import { ConnectButtonWrapper } from '../Web3WalletProvider';
import { priceFormatterFixed } from '../../helpers/digits';
import { setModal } from '../../redux/counterSlice';
import { useDispatch } from 'react-redux';
import { useFloorSweep } from '../floor-sweep/useFloorSweep';
import CardImageAO from './cardImageAO';
import RarityBadge from './badges/RarityBadge';
import ItemSourceBadge from './badges/ItemSourceBadge';
import { useRouter } from 'next/router';
import { CartBadge } from './badges/CartBadge';
import { formatErc404TokenId } from '../../helpers/erc404';
import { formatEther } from 'viem';
import UWWrappedBadge from './badges/UWWrappedBadge';

const Disconnected = ({ item }) => {
  const orders = item?.orders ? [...item.orders] : [];

  const price =
    (orders?.length &&
      orders?.sort((a, b) => Date.parse(b?.createdAt) - Date.parse(a?.createdAt))?.[0]?.price) ||
    null;

  return (
    <div className='relative mt-3 flex items-center justify-between'>
      <div className='grid h-8 place-content-center overflow-hidden'>
        <ConnectButtonWrapper>
          <button className='relative translate-y-0 break-words font-display text-xs font-semibold text-accent opacity-100 transition-all duration-500 hover:text-accent-light group-hover/price:translate-y-0 group-hover/price:opacity-100 md:translate-y-6 md:text-sm md:opacity-0'>
            Connect Wallet
          </button>
        </ConnectButtonWrapper>
      </div>
      <div className='flex items-end'>
        {price > 0 && (
          <span className='flex shrink-0 items-center whitespace-nowrap rounded-md border border-jacarta-100 p-1 dark:border-jacarta-600 lg:px-2 lg:py-1'>
            <ChainIcon name={item?.chain} tooltip={getTokenName(item?.chain)} sideGap={true} />
            <span className='text-sm font-medium leading-5 tracking-tight text-green'>
              {priceFormatterFixed(formatEther(price))}
            </span>
          </span>
        )}
      </div>
    </div>
  );
};

const Connected = ({ item, address, skeleton, collectionInfo, sweepCount }) => {
  const buttons = [];

  const itemOwner = item?.ownerObject;
  const isOnSale = item?.orders?.length > 0;
  const orders = item?.orders ? [...item.orders] : [];

  const price =
    (orders?.length &&
      orders?.sort((a, b) => Date.parse(b?.createdAt) - Date.parse(a?.createdAt))?.[0]?.price) ||
    null;

  if (skeleton) return <></>;

  const ConnectedButton = ({ title, modalConfig }) => {
    const dispatch = useDispatch();

    return (
      <div className='mt-0 flex items-center justify-between'>
        <button
          type='button'
          data-testid='nft-card-sell'
          className='font-display text-sm font-semibold text-accent'
          onClick={() => dispatch(setModal(modalConfig))}
        >
          {title}
        </button>
      </div>
    );
  };

  if (itemOwner?.owner === address && (item?.orders?.length === 0 || !item?.orders)) {
    buttons.push(
      <div className='mr-2'>
        <ConnectedButton
          key={'sellModal'}
          title={'Sell'}
          modalConfig={{
            key: 'sellModal',
            data: {
              ...item,
              isOnSale: isOnSale,
              owner: isOnSale,
            },
          }}
        />
      </div>,
    );
  }

  if (itemOwner?.owner === address && (item?.orders?.length === 0 || !item?.orders)) {
    buttons.push(
      <div className='mr-2'>
        <ConnectedButton
          key={'transferModal'}
          title={'Transfer'}
          modalConfig={{
            key: 'transferModal',
            data: {
              ...item,
              isOnSale: isOnSale,
              owner: itemOwner?.owner,
              collectionInfo,
            },
          }}
        />
      </div>,
    );
  }

  if (itemOwner?.owner === address && item?.orders?.length > 0) {
    buttons.push(
      <div className='mr-2'>
        <ConnectedButton
          key={'cancelSellModal'}
          title={'Cancel Sell'}
          modalConfig={{
            key: 'cancelSellModal',
            data: {
              kind: 1,
              chain: item?.chain,
              other: {
                ...item,
                isOnSale: isOnSale,
                owner: isOnSale,
              },
            },
          }}
        />
      </div>,
    );
  }

  if (itemOwner?.owner !== address) {
    buttons.push(
      <div>
        <ConnectedButton
          key={'bidsModal'}
          title={'Place bid'}
          modalConfig={{ key: 'bidsModal', data: item }}
        />
      </div>,
    );
  }

  return (
    <div
      className={[
        'flex w-full justify-between ', // display
        'mt-2 min-[380px]:mt-[-28px] ', // margin top
        'flex-col min-[380px]:flex-row ', // flex position
        'items-center min-[380px]:items-end ', // items position
        'h-[48px] min-[380px]:h-[64px]', // height
      ].join('')}
    >
      <div
        className={[
          'mb-[6px] flex items-center justify-between ',
          price > 0 ? '' : 'w-full max-[379px]:mt-[18px]',
        ].join('')}
      >
        {buttons?.map((button, index) => button)}
      </div>
      {price > 0 ? (
        <BuyButton
          item={item}
          address={address}
          sweepCount={sweepCount}
          itemOwner={itemOwner}
          isOnSale={isOnSale}
          price={price}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

const BuyButton = ({ item, address, sweepCount, itemOwner, isOnSale, price }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      setModal({
        key: 'buyModal',
        data: {
          ...item,
          isOnSale: isOnSale,
          owner: itemOwner?.owner,
        },
      }),
    );
  };

  const Button = () => {
    return (
      <button
        className='font-display text-sm font-semibold text-accent hover:text-accent-light'
        onClick={handleClick}
      >
        Buy now
      </button>
    );
  };

  return (
    <div className='cursor-pointer'>
      <div
        className={[
          'flex flex-col items-end justify-start overflow-hidden transition-all ',
          'h-[32px] ',
          itemOwner?.owner !== address && isOnSale && sweepCount === 0 ? 'hover:h-[64px]' : '',
        ].join('')}
        onClick={() =>
          itemOwner?.owner !== address && isOnSale && sweepCount === 0 && handleClick()
        }
      >
        <span
          className={[
            'flex items-center whitespace-nowrap rounded-md lg:px-2 lg:py-1 ',
            'border border-jacarta-100 p-1 dark:border-jacarta-600 ',
            // 'hidden',
          ].join('')}
          id='#nft_item_price'
        >
          <ChainIcon
            width={12}
            name={item?.chain}
            tooltip={getTokenName(item?.chain)}
            sideGap={true}
          />
          <span className='text-sm font-medium leading-5 tracking-tight text-green'>
            {priceFormatterFixed(formatEther(price))}
          </span>
        </span>
        <span
          className='mt-[6px] mb-[2px] flex h-[30px] w-[70px] items-center justify-center'
          id='#nft_item_buy'
        >
          <Button item={item} isOnSale={isOnSale} itemOwner={itemOwner} />
        </span>
      </div>
    </div>
  );
};

export default function NFTItemCard({ item, skeleton, ...props }) {
  const router = useRouter();
  const { sweepingItems, sweepCount } = useFloorSweep();
  const [image, setImage] = useState('');
  const [selected, setSelected] = useState(false);

  const contractType = item?.collection?.contractType;

  const collectionInfo = useGetCollectionGet(
    item?.collectionAddress || item?.collection?.address,
  ).data;

  const { address, isConnected } = useAccount();

  useEffect(() => {
    if (collectionInfo?.isDNFT) {
      setImage(collectionInfo.thumbnailAPI + item?.itemId);
    } else if (collectionInfo?.mirrorAddress && collectionInfo?.contractType === 'DN404') {
      const dn404imm = collectionInfo?.profilePhotoPath
        ? getCdnUrl(collectionInfo?.profilePhotoPath)
        : '/images/no_image.png';

      setImage(dn404imm);
    } else {
      const imageUrl = item?.imageFtpPath
        ? getCdnUrl(item?.imageFtpPath, constants.cdn.thumbnail, item?.updatedAt)
        : item?.metadata?.image
        ? item?.metadata?.image
        : constants.helpers.noImage;

      setImage(imageUrl);
    }
  }, [collectionInfo, item]);

  useEffect(() => {
    const _selected =
      sweepingItems.findIndex(
        (f) => f.collectionAddress == item.collectionAddress && f.itemId == item.itemId,
      ) > -1;

    setSelected(_selected && sweepCount > 0);
  }, [sweepingItems, sweepCount, item?.collectionAddress, item?.itemId]);

  const itemPath =
    item?.chain && item?.collectionAddress && item?.itemId
      ? getItemPath(item?.chain, item?.collectionAddress, item?.itemId)
      : '';

  const collectionPath =
    item?.chain && item?.collectionAddress
      ? getCollectionPath(item?.chain, item?.collection?.pathName)
      : '';

  const bidCount = item?._count?.orders;
  const itemOwner = item?.ownerObject;
  const showCheckBadge =
    itemOwner?.owner !== address &&
    router?.pathname?.startsWith('/collection/') &&
    !router?.pathname?.startsWith('/collection/explore_nfts');

  return (
    <article
      key={`${item?.id}`}
      {...props}
      className={selected ? 'rounded-2.5xl w-full bg-accent p-[2px]' : ''}
    >
      <div
        className={'group/price relative block overflow-hidden p-[0px] hover:shadow-lg '
          .concat('border border-jacarta-100 bg-white dark:border-jacarta-700 dark:bg-jacarta-700 ')
          .concat('rounded-2.5xl transition-shadow ')}
      >
        <figure className={`relative`}>
          <CardImageAO image={image} skeleton={skeleton} className={'flex'} href={itemPath}>
            <RarityBadge item={item} />
            <UWWrappedBadge item={item} />

            <ItemSourceBadge item={item} bidCount={bidCount} showCheckBadge={showCheckBadge} />
            <div className='absolute right-1 bottom-1 flex flex-col gap-1 sm:right-3 sm:bottom-3'>
              {showCheckBadge && <CartBadge item={item} />}
            </div>
          </CardImageAO>
        </figure>
        <div className='relative p-[0.7875rem] !pt-3 md:p-[1.1875rem]'>
          <div className='flex w-full items-center justify-between space-x-1'>
            <Link href={collectionPath}>
              <a className='truncate pb-0.5 text-sm text-jacarta-700 hover:text-accent-dark dark:text-jacarta-100 dark:hover:text-accent-light'>
                {collectionInfo?.name}
              </a>
            </Link>
            <ChainIcon name={item?.chain} tooltip={getChainName(item?.chain)} type={'chain'} />
          </div>
          <div className='mt-1 flex items-center justify-between'>
            <Link href={itemPath}>
              <a className={`${skeleton ? 'skeleton w-1/2' : ''} rounded`}>
                <span
                  className={`inline-block w-[130px] overflow-hidden text-ellipsis whitespace-nowrap break-keep font-display text-base text-jacarta-700 hover:text-accent dark:text-white`}
                >
                  {collectionInfo?.contractType == 'ERC404'
                    ? collectionInfo?.itemIdPrefix
                      ? `#${formatErc404TokenId(item?.itemId, collectionInfo?.itemIdPrefix)}`
                      : ''
                    : `#${item?.itemId ? item.itemId : ''}`}
                </span>
              </a>
            </Link>
          </div>

          {isConnected ? (
            <>
              <Connected
                item={item}
                address={address}
                skeleton={skeleton}
                collectionInfo={collectionInfo}
                sweepCount={sweepCount}
              />
            </>
          ) : (
            <Disconnected item={item} />
          )}
        </div>
      </div>
    </article>
  );
}
